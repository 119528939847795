import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Layout } from "antd";
import { User } from "./models/User";
import { deviceType, gtmAddLayer } from "./utilities";
import { AuthRest } from "./auth/SignInAuth";
import {
  /* 
  getGlobalLayout,
  setGlobalLayout, 
  */
  getUser,
  setUser
} from "./stores";
/* import { TGlobalLayout, validGlobalLayout } from "./models"; */
// Import Loaders
import { ProcessingFormLoader, WheelLoader } from "./components/Loaders";
// Import core components
import {
  AppNavbar,
  AppFooter,
  CookieConsent,
  SasTokenRefresher,
  StatusModal,
  MobileNavMenu,
  Routes,
} from "./components/core";
import PromotionTermsModal from "./components/Modals/PromotionTermsModal";
import ContactWorkshopPage from "./screens/ContactWorkshopPage";

const authRest = new AuthRest();

/**
 * URL Configuration params
 * ck_layout = default | mobilhub => if is provided in the URL, will set an specific layout
 */
export const AppEndConsumer: React.FC = () => {
  const dispatch = useDispatch();
  /* const globalLayout = useSelector(getGlobalLayout); */
  const [checkingUser, setCheckingUser] = useState<boolean>(true);
  const user = useSelector(getUser);

  const updateUser = (newValue: User | undefined) =>
    dispatch(setUser(newValue));

  /* 
  const login = async () => {
    // Perform login request
    const loginResponse = await authRest
      .urlLogin()
      .then((response) => response);
    // let's check if there are some error
    if (loginResponse.status === false) {
      message.error(loginResponse.message);
    } else {
      // set user in context
      updateUser(loginResponse.data.user);

      // track login event, if available tracking
      gtmAddLayer({
        User_ID: loginResponse.data.user.id.toString(),
        account_type: "Customer",
        event: "user_login",
      });
    }
    setCheckingUser(false);
  };

  const checkForErrors = async () => {
    const errors = await authRest.lookForErrors();
    if (errors.status === false) {
      message.error(errors.message);
    }
    // if (globalLayout === "mobilhub") window.location.href = authRest.oAuthUrls.mobilhub.login;
    updateUser(null);
    setCheckingUser(false);
  }; 
  */

  const checkForStoredSession = async () => {
    // request service to check if there is any stored and valid session
    const checkSession = await authRest.lookForActiveSession();

    // if there are not, set the proper context
    if (checkSession.status === false) {
      updateUser(null);
      setCheckingUser(false);
      // If is mobilhub user, send them to autenticate
      return false;
    } else {
      // If there is a session, let's set users data
      updateUser(checkSession.data.user);

      // And remember keep track of user login, if is available
      gtmAddLayer({
        User_ID: checkSession.data.user.id.toString(),
        account_type: "Customer",
        event: "user_login",
      });
      setCheckingUser(false);
      return true;
    }
  };

  /* 
  const checkMobilEmail = async (mobil_email: string) => {
    // Check for stored session first
    const haveStoredSession = await checkForStoredSession();
    if (haveStoredSession === false) {
      const isUser = await UserAPI.checkUser(mobil_email)
        .then((response) => response.data)
        .catch(() => false);
      if (
        (!isUser.isB2CUser && !isUser.isEndConsumer && !isUser.isWSUser) ||
        (isUser.isEndConsumer && (isUser.source || "").includes("mobilehub"))
      ) {
        window.location.href = authRest.oAuthUrls.mobilhub.login;
      } else {
        window.location.href = "/?action=login";
      }
    }
  }; 
  */

  const initialize = async () => {
    /**
     * Check URL Params
     * uncomment to resume mobilhub integration
     */
    /*
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const ck_layout = urlParams.get("ck_layout");
    const mobil_email = urlParams.get("mobil_email");
    var currentLayout: TGlobalLayout = "default";

    // If ck_layout exists but isn't valid
    if (
      ck_layout !== null &&
      ck_layout !== undefined &&
      validGlobalLayout.includes(ck_layout as TGlobalLayout) === false
    ) {
      dispatch(setGlobalLayout("default"));

      // If ck_layout exists and valid
    } else if (
      ck_layout !== null &&
      ck_layout !== undefined &&
      validGlobalLayout.includes(ck_layout as TGlobalLayout)
    ) {
      dispatch(setGlobalLayout(ck_layout as TGlobalLayout));
      currentLayout = ck_layout as TGlobalLayout;
      // Else, set default if globalLayout isn't defined
    } else {
      if (globalLayout === undefined) {
        dispatch(setGlobalLayout("default"));
      } else {
        currentLayout = globalLayout;
      }
    } 
    */

    /**
     * Check url hash
     */
    // Check if there is any hash
    // Uncomment to resume mobilhub integration
    /* 
      if (typeof mobil_email === "string" && currentLayout === "mobilhub") {
        await checkMobilEmail(mobil_email);
      } 
    */
    // Uncomment to resume social media login
    /* 
    if (window.location.hash !== "") {
      // Check url if there is a session token
      if (
        window.location.hash.indexOf("access_token") !== -1 ||
        window.location.hash.indexOf("id_token") !== -1
      ) {
        login();
      } else if (window.location.hash.indexOf("error") !== -1) {
        // Check url if there is a session token
        checkForErrors();
      } else {
        checkForStoredSession();
      }
    } else {
      // Check if there is a stored session
      checkForStoredSession();
    } 
    */

    checkForStoredSession();

    const rootE = document.getElementsByTagName("body");
    if (rootE) {
      rootE[0].className = `${deviceType()}-device`;
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  if (checkingUser /* || globalLayout === undefined */) return <WheelLoader mode="initial-loader" />;

  return (
    <>
      {/* 
      {globalLayout === "default" && (
        <>
          <CookieConsent />
          <StatusModal />
        </>
      )} 
      */}
      <CookieConsent />
        <StatusModal />
      <PromotionTermsModal />
      <SasTokenRefresher />
      <ProcessingFormLoader />
      <ContactWorkshopPage />
      <Layout /* className={globalLayout !== "default" ? `layout-${globalLayout}` : ""} */>
        <AppNavbar />
        <Routes user={user} />
        <AppFooter />
        {/* {globalLayout === "default" && user && <MobileNavMenu />} */}
        {user && <MobileNavMenu />}
      </Layout>
    </>
  );
};
