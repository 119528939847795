import React from "react";
import Icon from "@ant-design/icons";

const calendarCheckSvg = () => (
  <svg
    width="100%"
    height="16"
    viewBox="0 0 66 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.4614 1H15.8406C14.5048 1 13.4219 2.07998 13.4219 3.41221V11.0026C13.4219 12.3349 14.5048 13.4148 15.8406 13.4148H16.4614C17.7973 13.4148 18.8802 12.3349 18.8802 11.0026V3.41221C18.8802 2.07998 17.7973 1 16.4614 1Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M45.0718 1H44.451C43.1151 1 42.0322 2.07998 42.0322 3.41221V11.0026C42.0322 12.3349 43.1151 13.4148 44.451 13.4148H45.0718C46.4076 13.4148 47.4905 12.3349 47.4905 11.0026V3.41221C47.4905 2.07998 46.4076 1 45.0718 1Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.6328 6.82959H40.6598"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M43.9584 54.8246H8.25626C3.82188 54.8246 1 52.0103 1 47.5879V14.0662C1 9.64384 3.82188 6.82959 8.25626 6.82959H11.3039"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M47.9048 6.82959H51.7264C56.1608 6.82959 58.9827 9.64384 58.9827 14.0662V40.9463"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.03516 19.582L58.968 19.582"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.2502 25.6284H10.3164V32.5434H17.2502V25.6284Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.0729 25.6284H26.1392V32.5434H33.0729V25.6284Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.2502 41.0669H10.3164V47.9819H17.2502V41.0669Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.0729 41.0669H26.1392V47.9819H33.0729V41.0669Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M48.8947 25.6284H41.9609V32.5434H48.8947V25.6284Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M54.4303 60.9997C60.2679 60.9997 65.0003 56.2802 65.0003 50.4584C65.0003 44.6365 60.2679 39.917 54.4303 39.917C48.5927 39.917 43.8604 44.6365 43.8604 50.4584C43.8604 56.2802 48.5927 60.9997 54.4303 60.9997Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M60.1785 46.623L51.8499 54.9291L48.0767 51.1741"
      stroke="currentColor  "
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const CalendarCheckIcon = (props: any) => (
  <Icon component={calendarCheckSvg} {...props} />
);
export default CalendarCheckIcon;
